
import { defineComponent } from "vue";
import MainCardTest from "@/components/molecules/MainCardTest.vue";
import CardHome from "@/components/molecules/CardHome.vue";

export default defineComponent({
  name: "MyFavoriteSection",
  data() {
    return {
      slidesCollect: [
        {
          image: require("../../../assets/image/test-home-slider/test1.png"),
          name: "Unnamed Player",
          id: "100",
        },
        {
          image: require("../../../assets/image/test-home-slider/test2.png"),
          name: "Unnamed Player",
          id: "200",
        },
        {
          image: require("../../../assets/image/test-home-slider/test3.png"),
          name: "Unnamed Player",
          id: "300",
        },
        {
          image: require("../../../assets/image/test-home-slider/test4.png"),
          name: "Unnamed Player",
          id: "400",
        },
        {
          image: require("../../../assets/image/test-home-slider/test1.png"),
          name: "Unnamed Player",
          id: "500",
        },
        {
          image: require("@/assets/image/test-home-slider/test2.png"),
          name: "Unnamed Player",
          id: "600",
        },
        {
          image: require("@/assets/image/test-home-slider/test3.png"),
          name: "Unnamed Player",
          id: "700",
        },
        {
          image: require("@/assets/image/test-home-slider/test3.png"),
          name: "Unnamed Player",
          id: "800",
        },
      ],
    };
  },
  components: {
    MainCardTest,
    CardHome,
  },
  mounted() {},
  methods: {
  },
});
