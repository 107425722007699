
import { defineComponent } from "vue";
import Lock from "@/assets/svg/Lock.svg?inline";
export default defineComponent({
  name: "MainCard",
  data() {
    return {};
  },
  components: {
    Lock,
  },
  mounted() {},
  methods: {},
});
